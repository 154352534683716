import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Box,
  Container,
  Grid,
  TextField,
  Paper,
  Typography,
  AppBar,
  Toolbar
} from "@mui/material";
import Footer from './Footer';
import axios from "axios";

const languages = ["it", "uk", "tr", "zh-CN", "my"];
function App() {
  const [input, setInput] = useState("");
  const [translations, setTranslations] = useState({});
  const [, setLoading] = useState(false);

  const debouncedTimeoutRef = useRef(null); // 使用useRef来持久化timeout


  // 函数移到组件外部，防止每次渲染时都被重新创建
  const debounce = (fn, delay) => {
    clearTimeout(debouncedTimeoutRef.current); // 清除上一个timeout
    debouncedTimeoutRef.current = setTimeout(() => {
      fn();
    }, delay);
  };

  // Function to call Google Translate API
  // Assuming you have a function to make API requests

  const fetchTranslation = (text, targetLang) => {
    return new Promise(async (resolve, reject) => {
      const apiKey = process.env.REACT_APP_GOOGLE_TRANSLATE_API_KEY;
      const url = `https://translation.googleapis.com/language/translate/v2?key=${encodeURIComponent(
        apiKey
      )}`;
      if(text.length > 420) {
        text = text.substring(0, 420);
        text += '...';
      }

      try {
        const response = await axios.post(url, {
          q: text,
          source: "de",
          target: targetLang,
          format: "text",
        });

        const translation = response.data.data.translations[0].translatedText;
        // Update the translations state with the new translation
        setTranslations((prevTranslations) => ({
          ...prevTranslations,
          [targetLang]: translation,
        }));
        resolve(translation);
      } catch (error) {
        reject(error);
      }
    });
  };

  // Call API for every supported language
  const translateText = useCallback(async (text) => {
    setLoading(true);
    setTranslations({});

    const translationPromises = languages.map((lang) => fetchTranslation(text, lang));

    try {
      const results = await Promise.all(translationPromises);
      const newTranslations = {};
      results.forEach((translation, index) => {
        const lang = languages[index];
        newTranslations[lang] = translation;
      });
      setTranslations(newTranslations);
    } catch (error) {
      console.error("Error fetching translations:", error);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    if (input) {
      debounce(() => translateText(input), 1800);
    } else {
      setTranslations({});
    }

    return () => clearTimeout(debouncedTimeoutRef.current);
  }, [input, translateText]);

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  return (
    <div className="App">
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <AppBar position="static" color="primary">
        <Toolbar>
          <Typography variant="h7" color="inherit" noWrap>
            TranslateHub für Klubschule Migros
          </Typography>
        </Toolbar>
      </AppBar>
      <Paper elevation={0} sx={{ p: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              id="outlined-multiline-static"
              label="Enter German text"
              multiline
              rows={8}
              value={input}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {Object.entries(translations).map(([language, translation]) => (
              <Box
                key={language}
                sx={{
                  mb: 2,
                }}
              >
                <Paper variant="outlined" elevation={0} sx={{ p: 2, height: "100%" }}>
                  <Typography>{translation}</Typography>
                </Paper>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Paper>
      <Footer />
    </Container>
    </div>
  );
}

export default App;
